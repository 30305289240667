import { IAddProduct } from "../../models/forms/IAddProduct";


export const initialState: IAddProduct = {
  Description: "",
  ProductCode: "",
  Sku: "",
  Name: "",
  Variety: "",
  TenantId: -1,
  WeightUnit: ""
}

export const initialStateWeightedProduct = {
  Weight: -1,
  Sku: "",
  Name: "",
  Image: "",
  Id: -1,
  AddedDate: ""
}