import React, { useEffect, useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import FormikFormErrorHandler from "./FormikFormErrorHandler";
import { useField } from "formik";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useStore } from "../../contexts/store-provider";
import { DateFormatInForBackend } from "../../constants/date-picker-constant";
import { enIN } from "date-fns/locale";

registerLocale("en-IN", enIN);

interface IProps {
  children: React.ReactNode;
  className: string;
  name: string;
  "data-testid"?: string;
  intl: any;
  onChange: () => void;
  value: any;
  disabled: boolean;
  readOnly: boolean;
  label?: string;
}

const FormikFormDatePicker = (props: IProps) => {
  const [field, meta, helpers] = useField(props);
  const { preferencesStore } = useStore();
  const [focus, setFocus] = useState(false);
  const language = preferencesStore.getLanguage;
  const [calendarIsOpen, setCalendarIsOpen] = useState(false);

  const handleCalendarClose = () => {
    if (calendarIsOpen) setCalendarIsOpen(false);
  };

  const onClickCalendarIcon = () => {
    setCalendarIsOpen(!calendarIsOpen);
  };

  const blurHandler = () => {
    if (!props.value) setFocus(false);
  };

  useEffect(() => {
    if (props.value) setFocus(true);
  }, [props.value]);

  return (
    <>
      <DatePicker
        {...props}
        {...field}
        dateFormat={DateFormatInForBackend}
        locale="en-IN"
        // id="calendar-icon"
        selected={field.value}
        onChange={(date: Date) => {
          helpers.setValue(date);
        }}
        open={calendarIsOpen}
        onClickOutside={handleCalendarClose}
        onInputClick={() => {
          setCalendarIsOpen(true);
        }}
        onFocus={() => setFocus(true)}
        onBlur={blurHandler}
        onSelect={(date: Date) => {
          helpers.setValue(date);
          handleCalendarClose();
        }}
      />
      {props.label && (
        <label className={`${focus ? "label-focus" : ""}`}>
          <span>
            <FormattedMessage id={props.label} />
          </span>
        </label>
      )}
      {/* <span
        className="material-symbols-outlined calendar-icon"
        onClick={props.disabled ? () => false : onClickCalendarIcon}
      >
        calendar_month
      </span> */}
      <FormikFormErrorHandler intl={props.intl} meta={meta} />
    </>
  );
};

export default injectIntl(FormikFormDatePicker);
