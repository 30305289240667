import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { IOption } from "../../../models/ICommon";
import LoaderButton from "../../../shared-components/button/loader-button";
import FormikFormInput from "../../../shared-components/FormikFormHandlers/FormikFormInput";
import FormikFormSearchInput from "../../../shared-components/FormikFormHandlers/FormikFormSearchInput";
import FormLoader from "../../../shared-components/FormLoader/FormLoader";
import Label from "../../../shared-components/label/label";
import "./add-edit-product-form.css";
import { WeightedProductValidateSchema } from "../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import { useStore } from "../../../contexts/store-provider";

interface IProps {
  id: number;
  // userControlOptions: IOption[];
  submitHandler: (data: any) => void;
  onModalClosed: () => void;
  getWeightedProductState: boolean;
  addUpdateUserLoading: boolean;
  initialValues: any;
  userControlOptions: IOption[];
  allData: any;
  SelectedSku: any;
  tenantId: any;
  getAllProductByQuery: any;
}

const AddWeightedProductForm = (props: IProps) => {
  const [results, setResults] = useState<string[]>([]);
  const [product, setProduct] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("Loading...");
  const { productStore, preferencesStore } = useStore();
  const { allFilteredProducts, GetAllProductByQuery } = productStore;

  useEffect(() => {
    const getData = setTimeout(() => {
      GetAllProductByQuery(props.tenantId, product);

      if (product.length > 0) {
        const productNames: string[] = allFilteredProducts.map(
          (item: { Name: any }) => item.Name
        );
        // setProduct(e.target.value)
        const filtered = productNames.filter(
          (d) =>
            product &&
            product.length > 0 &&
            d.toLowerCase().includes(product.toLowerCase())
        );
        setResults(filtered);
        setLoadingText("No Product");
      } else {
        setResults([]);
      }
    }, 500);

    return () => clearTimeout(getData);
  }, [product]);

  return (
    <>
      <Modal
        className="correction-modal fancy-modal"
        show={true}
        centered
        onHide={() => {}}
      >
        <Modal.Header>
          <Modal.Title>
            {props.id === -1 ? (
              <FormattedMessage id="add_product" />
            ) : (
              <FormattedMessage id="update_product" />
            )}
            <button
              onClick={props.onModalClosed}
              // disabled={props.addUpdateUserLoading}
              className="Crossicons"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="23"
                height="23"
                fill="currentColor"
                className="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </button>
          </Modal.Title>
        </Modal.Header>

        <Formik
          initialValues={props.initialValues}
          enableReinitialize
          validationSchema={WeightedProductValidateSchema}
          validateOnBlur={false}
          onSubmit={async (values, { resetForm }) => {
            props.submitHandler(values);
            resetForm();
          }}
        >
          {({ setFieldValue }) => {
            return (
              <Form className="user-form">
                {props.getWeightedProductState ? (
                  <FormLoader loading={props.getWeightedProductState} />
                ) : null}
                <Modal.Body>
                  <span className="modal-wave">
                    <svg
                      width="447"
                      height="116"
                      viewBox="0 0 447 116"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.4"
                        d="M55.2678 22.3777C-49.5465 -14.1611 7.16534 -48.8529 136.242 -34.0647L214.579 -30.0724L448.26 -8.82579L459.956 104.858C396.401 148.386 406.862 51.7166 297.501 67.1292C188.139 82.5419 225.278 33.322 176.928 20.0906C128.579 6.8592 91.4243 34.9821 55.2678 22.3777Z"
                        fill="rgba(126, 166, 255, 0.4)"
                      ></path>
                    </svg>
                  </span>
                  <div className="row">
                    <div className="result-box">
                      <div className="col-12">
                        <div className="form-group formSet">
                          <Label
                            className="label-style  col-form-label"
                            required={true}
                            label="product_name"
                          />
                          <Field
                            data-testid="Name"
                            className="form-control"
                            name="Name"
                            autoComplete="off"
                            type="text"
                            placeholder=""
                            onKeyUp={(e: any) => {
                              setProduct(e.target.value);
                              setIsLoading(true);
                              setLoadingText("Loading...");
                              if (e.target.value.length > 0) {
                                setIsLoading(true);
                              } else {
                                setIsLoading(false);
                              }
                              // const productNames: string[] = props.allData.map((item: { Name: any; }) => item.Name);
                              // // setProduct(e.target.value)
                              // const filtered = productNames.filter(d => e.target.value.length > 0 && d.toLowerCase().includes(e.target.value.toLowerCase()));
                              // if (filtered.length === 0) {
                              //   setFieldValue("Sku", "")
                              //   setResults([])
                              // } else {
                              //   setResults([...filtered])
                              // }
                            }}
                            as={FormikFormSearchInput}
                          />
                        </div>

                        <div className="results-list custom-select">
                          {" "}
                          {results && results.length > 0
                            ? results.map((result) => {
                                return (
                                  <div
                                    className="search-result"
                                    key={result}
                                    onClick={(
                                      e: React.MouseEvent<HTMLInputElement>
                                    ) => {
                                      // setProduct(e.target.innerText)
                                      const selectedProductName = (
                                        e.target as HTMLElement
                                      ).innerText;
                                      setFieldValue(
                                        "Name",
                                        selectedProductName
                                      );
                                      setFieldValue(
                                        "Sku",
                                        allFilteredProducts.filter(
                                          (product: { Name: string }) =>
                                            product.Name === selectedProductName
                                        )[0].Sku
                                      );
                                      setResults([]);
                                      setIsLoading(false);
                                    }}
                                  >
                                    {result}
                                  </div>
                                );
                              })
                            : isLoading && (
                                <div
                                  className="search-result"
                                  key={"-1"}
                                  // onClick={(e: React.MouseEvent<HTMLInputElement>) => {
                                  //   // setProduct(e.target.innerText)
                                  //   const selectedProductName = (e.target as HTMLElement).innerText;
                                  //   setFieldValue("Name", selectedProductName)
                                  //   setFieldValue("Sku", allFilteredProducts.filter((product: { Name: string; }) => product.Name === selectedProductName)[0].Sku)
                                  //   setResults([])
                                  // }}
                                >
                                  {loadingText}
                                </div>
                              )}
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="form-group formSet">
                        <Label
                          className="label-style  col-form-label"
                          required={true}
                          label="Sku"
                        />

                        <Field
                          data-testid="Sku"
                          className="form-control"
                          disabled={props.id > 0 ? true : false}
                          name="Sku"
                          type="text"
                          invalidcharacter={[" "]}
                          validcharacter={["@", "#", "$", "-", "_", "."]}
                          placeholder=""
                          as={FormikFormInput}
                        />
                      </div>
                    </div>
                  </div>
                  <span className="footer-wave">
                    <svg
                      width="333"
                      height="61"
                      viewBox="0 0 333 61"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.4"
                        d="M-0.322477 0.641086L-0.418408 0.55164L-9.20939 59.4297L23.6588 106.206L154.575 130.423C236.759 117.931 383.93 93.3326 315.142 94.879C246.355 96.4253 215.362 64.2785 215.362 64.2785C215.362 64.2785 185.497 26.9117 117.864 33.4279C42.6115 40.6783 10.6143 10.8399 -0.322477 0.641086Z"
                        fill="rgba(126, 166, 255, 0.4)"
                      ></path>
                    </svg>
                  </span>
                </Modal.Body>
                <Modal.Footer>
                  <div className="d-flex justify-content-center ">
                    <div className="row tab-action g-0">
                      <div className="col-12">
                        <Button
                          variant="light-danger"
                          type="button"
                          onClick={props.onModalClosed}
                          disabled={props.addUpdateUserLoading || isLoading}
                        >
                          <FormattedMessage id="button_cancel" />
                        </Button>
                        <LoaderButton
                          type="submit"
                          isLoading={props.addUpdateUserLoading}
                          disabled={isLoading}
                          text="button_save"
                        />
                      </div>
                    </div>
                  </div>
                </Modal.Footer>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

export default AddWeightedProductForm;
