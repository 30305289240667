/*
<summary>
  This functional component "DeletePopupHOC" provides props to the child component.
  1. State "showDeletePopup" that manages the show/hide of pop up
  2. Function "deletePopupToggleHandler" to toggle the show/hide popup state "showDeletePopup"
  Developer:Mohammad Saquib Khan, Created Date:04-April-2024
</summary>
<returns>Returns JSX</returns>
*/

import React, { useState } from "react";

const DeletePopupHOC = (Component: any) =>
  function Comp(props: any) {
    const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);

    const deleteToggleHandler = () => {
      setShowDeletePopup(!showDeletePopup);
    };

    return (
      <Component
        showDeletePopup={showDeletePopup}
        deletePopupToggleHandler={deleteToggleHandler}
        {...props}
      />
    );
  };

export default DeletePopupHOC;
