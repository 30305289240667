import { useState } from "react";

const FilterOrderPopupHOC = (Component: any) =>
  function Comp(props: any) {
    const [showFilterOrderPopup, setShowFilterProductPopup] = useState<boolean>(false);

    const filterOrderPopupToggleHandler = () => {
      setShowFilterProductPopup(!showFilterOrderPopup);
    };

    return (
      <Component
        showfilterOrderPopup={showFilterOrderPopup}
        filterOrderPopupToggleHandler={filterOrderPopupToggleHandler}
        {...props}
      />
    );
  };

export default FilterOrderPopupHOC