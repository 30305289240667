import { action, computed, makeObservable, observable, configure } from "mobx";
import { IObservableInitialState, IOption } from "../../models/ICommon";
import { ICommonState } from "../../models/state/ICommonState";
import URLConstants from "../../constants/url-constants";
import * as baseService from "../service/base-service";
import IApiResponse, {
  IApiSuccessResponse,
} from "../../models/response/IApiResponse";
import {
  IProductList,
  IProductListVM,
  IProductSkuKeyValueListVM,
  IProductSkuKeyValueVM,
  IProductTrimList,
  IProductVM,
  IWeightedProductList,
  IWeightedProductListVM,
  IWeightedProductVM,
} from "../../models/response/IProductResponse";
import {
  IAddProduct,
  IUpdateWeightedProductSKU,
} from "../../models/forms/IAddProduct";
import { toast } from "react-hot-toast";
import { formatMessage } from "../../translations/format-message";
import {
  initialState as GetAllProductState,
  initialWeightedProductState as GetAllWeighedProductState,
} from "../initialState/get-all-product-state";
import { getProductIdFromToken } from "../../helpers/localstorage-helper";
import { IProductState } from "../../models/state/IProductState";
import {
  initialState,
  initialStateWeightedProduct,
} from "../initialState/add-edit-product-state";
import moment from "moment";
import config from "../../helpers/config-helper";
import { IWeightedProductFilter } from "../../models/IWeightedProductFilter";

const appConfig = config();
const dateFormat = appConfig.REACT_APP_DATE_TIME_FORMAT_INDIAN;

configure({
  enforceActions: "never",
});

export class ProductStore implements IProductState, ICommonState {
  inProgress = false;
  error = "";
  productAccessId = getProductIdFromToken();
  initialStateValue: IObservableInitialState = {
    success: false,
    error: "",
    inProgress: false,
  };

  productList: IProductListVM = GetAllProductState;
  weightedProductList: IWeightedProductListVM = GetAllWeighedProductState;
  product: any | undefined = undefined;
  productState = { ...this.initialStateValue };
  activateProductAccountState = { ...this.initialStateValue };
  addUpdateProductState = { ...this.initialStateValue };
  weightedProductState = { ...this.initialStateValue };
  deleteProductState = { ...this.initialStateValue };
  resendActivationLinkState = { ...this.initialStateValue };
  getProductState = { ...this.initialStateValue };
  selectedTenantId = -1;
  weightedProduct: any | undefined = undefined;
  allProductSkuKeyValueList: IProductSkuKeyValueListVM | undefined;
  allProductSkuKeyValueState = { ...this.initialStateValue };
  updateWeightedProductSkuState = { ...this.initialStateValue };
  getWeightedProductState = { ...this.initialStateValue };
  filteredProducts: any | undefined = undefined;
  exportFileState = { ...this.initialStateValue };
  base64String: any = { fileString: "" };
  weightedPendingProductState = { ...this.initialStateValue };
  getPendingWeightedProductState = { ...this.initialStateValue };
  updatePendingWeightedProductSkuState = { ...this.initialStateValue };
  deletePendingProductState = { ...this.initialStateValue };

  constructor() {
    makeObservable(this, {
      product: observable,
      productState: observable,
      productList: observable,
      weightedProductList: observable,
      addUpdateProductState: observable,
      deleteProductState: observable,
      activateProductAccountState: observable,
      resendActivationLinkState: observable,
      GetProductListService: action,
      allProduct: computed,
      AddProductService: action,
      UpdateProductService: action,
      GetProductByIdService: action,
      resetAddUpdateProductState: action,
      resetProductDetail: action,
      resetDeleteProduct: action,
      productDetail: computed,
      selectedTenantId: observable,
      setSelectedTenantId: action,
      allWeightedProduct: computed,
      GetWeightedProductListService: action,
      weightedProductState: observable,
      getProductState: observable,
      allProductTrim: computed,
      weightedProductDetail: computed,
      weightedProduct: observable,
      GetWeightedProductByIdService: action,
      productSkuKeyValueOptions: computed,
      GetAllProduct: action,
      allProductSkuKeyValueState: observable,
      allProductSkuKeyValueList: observable,
      UpdateWeightedProductSku: action,
      updateWeightedProductSkuState: observable,
      resetUpdateWeightedProductState: action,
      getWeightedProductState: observable,
      resetWeightedProductDetail: action,
      GetAllProductByQuery: action,
      filteredProducts: observable,
      allFilteredProducts: computed,
      ExportFileService: action,
      exportFileState: observable,
      base64String: observable,
      resetExportFileState: action,
      weightedPendingProductState: observable,
      GetPendingWeightedProductsListService: action,
      GetPendingWeightedProductByIdService: action,
      getPendingWeightedProductState: observable,
      UpdatePendingWeightedProductSku: action,
      updatePendingWeightedProductSkuState: observable,
      resetAddUpdatePendingroductState: action,
      resetWeightedProductState: action,
      DeletePendingProductService: action,
      deletePendingProductState: observable,
      resetDeletePendingProduct: action,
    });
  }

  /**
   * This function is used to map productList to allProduct List suitable for Grid Component.
   * @returns allProductsList
   */
  get allProduct(): IProductList[] {
    let productData: IProductList[] = [];
    if (this.productList?.Products && this.productList.Products?.length > 0)
      this.productList.Products.map((product: any) => {
        productData.push({
          Description: product.Description || "N/A",
          ProductCode: product.ProductCode,
          UserId: product.UserId,
          Sku: product.Sku,
          Id: product.ID,
          CreatedBy: product.CreatedBy,
          CreatedDate: product.CreatedDate,
          UpdatedBy: product.UpdatedBy,
          UpdatedDate: product.UpdatedDate,
          Name: product.Name,
          Variety: product.Variety || "N/A",
          WeightUnit: product.WeightUnit || "",
        });
      });
    return productData;
  }

  /**
   * This function is used to map productList to allProduct List suitable for Grid Component.
   * @returns allProductsList
   */
  get allProductTrim(): IProductTrimList[] {
    let productData: IProductTrimList[] = [];
    if (this.productList?.Products && this.productList.Products?.length > 0)
      this.productList.Products.map((product: any) => {
        productData.push({
          Description: product.Description || "N/A",
          ProductCode: product.ProductCode,
          Sku: product.Sku,
          Id: product.ID,
          Name: product.Name,
          Variety: product.Variety || "N/A",
          WeightUnit: product.WeightUnit || "",
        });
      });
    return productData;
  }

  get allWeightedProduct(): IWeightedProductList[] {
    let productData: IWeightedProductList[] = [];
    if (
      this.weightedProductList?.Products &&
      this.weightedProductList.Products?.length > 0
    ) {
      this.weightedProductList.Products.map((product: any, index) => {
        productData.push({
          // No: index + 1,
          Sku: product.Sku || "-",
          Id: product.Id,
          Name:
            product.Name && product.Name.length > 15
              ? product.Name?.substring(0, 15) + "..."
              : product.Name,
          Weight: product.Weight.toFixed(3) || "-",
          Image: product.Image,
          AddedDate: moment(product.AddedDate).format(dateFormat),
          CreatedBy: product.CreatedBy,
          CreatedDate: product.CreatedDate,
          UpdatedBy: product.UpdatedBy,
          UpdatedDate: product.UpdatedDate,
          WeightUnit: product.WeightUnit || "",
        });
      });
    }
    return productData;
  }

  get productSkuKeyValueOptions(): IOption[] {
    let productData: IOption[] = [];
    if (
      this.allProductSkuKeyValueList?.Products &&
      this.allProductSkuKeyValueList.Products?.length > 0
    ) {
      this.allProductSkuKeyValueList.Products.map((product: any) => {
        productData.push({
          id: product.Id,
          value: product.Name,
          disabled: product.Name === "DEFAULT",
          // Name: product.Name && product.Name.length > 15 ? product.Name?.substring(0, 15) + '...' : product.Name,
        });
      });
    }
    productData = productData.sort((a, b) =>
      a.value === "DEFAULT" ? -1 : b.value === "DEFAULT" ? 1 : 0
    );
    return productData;
  }

  get allProductSkuList(): IProductSkuKeyValueVM[] {
    let productData: IProductSkuKeyValueVM[] = [];
    if (
      this.allProductSkuKeyValueList?.Products &&
      this.allProductSkuKeyValueList.Products?.length > 0
    ) {
      this.allProductSkuKeyValueList.Products.map(
        (product: IProductSkuKeyValueVM) => {
          productData.push({
            Id: product.Id,
            Name: product.Name,
            Sku: product.Sku,
            // Name: product.Name && product.Name.length > 15 ? product.Name?.substring(0, 15) + '...' : product.Name,
          });
        }
      );
    }

    return productData;
  }

  /**
   * This function is used to Get All Products List data with pagination information by calling an API.
   * @param currentPage : Current Page Number
   * @param pagerSize : Page Size
   * @param tenantId: To view the associated products
   * @returns
   */
  GetProductListService = (
    currentPage: number,
    pagerSize: number,
    tenantId: number
  ) => {
    if (tenantId === -1) {
      this.productList = GetAllProductState;
      return;
    }
    this.inProgress = true;
    this.getProductState.inProgress = true;
    const url =
      URLConstants.GetProductsList +
      `?PageNo=${currentPage}` +
      `&PageSize=${pagerSize}` +
      `&TenantId=${tenantId}`;

    this.inProgress = false;
    return baseService
      .getRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<IProductListVM>>) => {
        if (response.data.Error) {
          this.getProductState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else {
          this.productList = response.data.Data;
          this.getProductState.success = true;
        }
      })
      .catch((err: string) => {
        this.error = err;
        toast.error(formatMessage("SomethingWentWrong"));
      })
      .finally(
        action(() => {
          this.inProgress = false;
          this.getProductState.inProgress = false;
        })
      );
  };

  GetWeightedProductListService = (
    currentPage: number,
    pagerSize: number,
    tenantId: number,
    order: string,
    filter: string
  ) => {
    if (tenantId === -1) {
      this.weightedProductList = GetAllWeighedProductState;
      return;
    }

    this.weightedProductState.inProgress = true;
    const url =
      URLConstants.GetWeightedProductsList +
      `/${tenantId}` +
      `?PageNo=${currentPage}` +
      `&PageSize=${pagerSize}` +
      `&order=${order}` +
      `&filter=${filter}`;

    return baseService
      .getRequest(url)
      .then(
        (
          response: IApiResponse<IApiSuccessResponse<IWeightedProductListVM>>
        ) => {
          if (response.data.Error) {
            this.error = response.data.Message;
            this.weightedProductState.error = response.data.Message;
            toast.error(formatMessage(response.data.Message));
          } else {
            this.weightedProductList = response.data.Data;
            this.weightedProductState.success = true;
          }
        }
      )
      .catch((err: string) => {
        this.weightedProductState.error = err;
        this.weightedProductList = GetAllWeighedProductState;
        toast.error(formatMessage("SomethingWentWrong"));
      })
      .finally(
        action(() => {
          this.weightedProductState.inProgress = false;
        })
      );
  };

  /**
   * This function provides initail values to the Add Product Form.
   * @returns Product Details
   */
  get productDetail() {
    if (this.product) {
      return {
        Description: this.product.Description,
        ProductCode: this.product.ProductCode,
        UserId: this.product.UserId,
        Sku: this.product.Sku,
        Name: this.product.Name,
        Variety: this.product.Variety,
        TenantId: this.product.TenantId,
        WeightUnit: this.product.WeightUnit,
      };
    }
    return initialState;
  }

  /**
   * This function provides initail values to the Add Product Form.
   * @returns Weighted Product Details
   */
  get weightedProductDetail() {
    if (this.weightedProduct) {
      return {
        Weight: this.weightedProduct.Weight,
        Sku: this.weightedProduct.Sku || "-",
        Name: this.weightedProduct.Name || "-",
        Image: this.weightedProduct.Image || "-",
        Id: this.weightedProduct.Id || "-",
        AddedDate: moment(this.weightedProduct.AddedDate).format(dateFormat),
        WeightUnit: this.weightedProduct.WeightUnit,
      };
    }
    return initialStateWeightedProduct;
  }
  /**
   * This function is used to Get Product Details by Id by calling an API.
   * @param id : The Product Identifier
   * @returns
   */
  public GetWeightedProductByIdService = (tenantId: number, id: number) => {
    this.getWeightedProductState.inProgress = true;
    let url = `${URLConstants.GetWeightedProductById}/${tenantId}/${id}`;
    return baseService
      .getRequest(url)
      .then(
        (response: IApiResponse<IApiSuccessResponse<IWeightedProductVM>>) => {
          if (response.data.Error) {
            this.getWeightedProductState.error = response.data.Message;
          } else {
            this.weightedProduct = response.data.Data;
          }
        }
      )
      .catch((err: string) => {
        this.getWeightedProductState.error = err;
      })
      .finally(
        action(() => {
          this.getWeightedProductState.inProgress = false;
        })
      );
  };

  /**
   * This function is used to Add New Product by calling an API.
   * @param data : Product Data
   * @returns
   */
  AddProductService = (data: IAddProduct) => {
    this.addUpdateProductState.inProgress = true;
    let url = URLConstants.AddProduct;
    return baseService
      .postRequest(url, data)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.addUpdateProductState.error = response.data.Message;
        } else this.addUpdateProductState.success = true;
      })
      .catch((err: string) => {
        this.addUpdateProductState.error = err;
      })
      .finally(
        action(() => {
          this.addUpdateProductState.inProgress = false;
        })
      );
  };

  /**
   * This function is used to Updating Existing Product Details by calling an API.
   * @param id : The Product Identifier
   * @param data
   * @returns
   */
  UpdateProductService = (id: number, data: IAddProduct) => {
    this.addUpdateProductState.inProgress = true;
    let url = URLConstants.UpdateProduct;
    return baseService
      .putRequest(url, data)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.addUpdateProductState.error = response.data.Message;
        } else this.addUpdateProductState.success = true;
      })
      .catch((err: string) => {
        this.addUpdateProductState.error = err;
      })
      .finally(
        action(() => {
          this.addUpdateProductState.inProgress = false;
        })
      );
  };

  /**
   * This function is used to Delete Product by calling an API.
   * @param id : The Product Identifier
   * @returns
   */
  DeleteProductService = (id: number, tenantId: number) => {
    this.deleteProductState.inProgress = true;
    let url = URLConstants.DeleteProduct + "/" + id + "?tenantId=" + tenantId;
    return baseService
      .deleteRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.deleteProductState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else this.deleteProductState.success = true;
      })
      .catch((err: string) => {
        this.deleteProductState.error = err;
      })
      .finally(
        action(() => {
          this.deleteProductState.inProgress = false;
        })
      );
  };

  /**
   * This function is used to Get Product Details by Id by calling an API.
   * @param id : The Product Identifier
   * @returns
   */
  public GetProductByIdService = (id: number, tenantId: number) => {
    this.productState.inProgress = true;
    let url = `${URLConstants.GetProductById}/ProductDetail/${id}?tenantId=${tenantId}`;
    return baseService
      .getRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<IProductVM>>) => {
        if (response.data.Error) {
          this.productState.error = response.data.Message;
        } else {
          this.product = response.data.Data;
        }
      })
      .catch((err: string) => {
        this.productState.error = err;
      })
      .finally(
        action(() => {
          this.productState.inProgress = false;
        })
      );
  };

  public GetProductByIdLocalService = (id: number, tenantId: number) => {
    this.product = this.allProduct.filter((p) => p.Id === id)[0];
  };

  /*
    This function is used to reset all AddProduct observables to their initial values.
    */
  resetAddUpdateProductState = () => {
    this.addUpdateProductState.inProgress = false;
    this.addUpdateProductState.success = false;
    this.addUpdateProductState.error = "";
  };

  resetAddUpdatePendingroductState = () => {
    this.updatePendingWeightedProductSkuState = { ...this.initialStateValue };
  };

  resetProductDetail = () => {
    this.product = undefined;
    this.productState = { ...this.initialStateValue };
  };

  resetWeightedProductDetail = () => {
    this.weightedProduct = undefined;
  };

  resetDeleteProduct = () => {
    this.deleteProductState = { ...this.initialStateValue };
  };
  resetDeletePendingProduct = () => {
    this.deletePendingProductState = { ...this.initialStateValue };
  };

  setSelectedTenantId = (tenantId: number) => {
    this.selectedTenantId = tenantId;
  };

  resetWeightedProductState = () => {
    this.weightedProductState = { ...this.initialStateValue };
  };

  GetAllProduct = (tenantId: number) => {
    // if (tenantId === -1) {
    //   this.productList = GetAllProductState;
    //   return;
    // }
    this.allProductSkuKeyValueState.inProgress = true;
    const url = `${URLConstants.GetAllProduct}/${tenantId}`;
    // const url = `${URLConstants.GetAllProduct}/${tenantId}?ProductName=${query}`;

    return baseService
      .getRequest(url)
      .then(
        (
          response: IApiResponse<IApiSuccessResponse<IProductSkuKeyValueListVM>>
        ) => {
          if (response.data.Error) {
            this.allProductSkuKeyValueState.error = response.data.Message;
            toast.error(formatMessage(response.data.Message));
          } else {
            this.allProductSkuKeyValueList = response.data.Data;
            this.allProductSkuKeyValueState.success = true;
          }
        }
      )
      .catch((err: string) => {
        this.error = err;
        toast.error(formatMessage("SomethingWentWrong"));
      })
      .finally(
        action(() => {
          this.inProgress = false;
          this.allProductSkuKeyValueState.inProgress = false;
        })
      );
  };

  get allFilteredProducts() {
    let filteredData: any[] = [];
    if (
      this.filteredProducts?.Products &&
      this.filteredProducts.Products?.length > 0
    ) {
      this.filteredProducts.Products.map((product: any) => {
        filteredData.push({
          Sku: product.Sku,
          Id: product.Id,
          Name: product.Name,
        });
      });
    }
    return filteredData;
  }

  GetAllProductByQuery = (tenantId: number, query: string) => {
    this.allProductSkuKeyValueState.inProgress = true;
    const url = `${URLConstants.GetAllProduct}/${tenantId}?ProductName=${query}`;

    return baseService
      .getRequest(url)
      .then(
        (
          response: IApiResponse<IApiSuccessResponse<IProductSkuKeyValueListVM>>
        ) => {
          if (response.data.Error) {
            this.allProductSkuKeyValueState.error = response.data.Message;
            toast.error(formatMessage(response.data.Message));
          } else {
            this.filteredProducts = response.data.Data;
            this.allProductSkuKeyValueState.success = true;
          }
        }
      )
      .catch((err: string) => {
        this.error = err;
        toast.error(formatMessage("SomethingWentWrong"));
      })
      .finally(
        action(() => {
          this.inProgress = false;
          this.allProductSkuKeyValueState.inProgress = false;
        })
      );
  };

  UpdateWeightedProductSku = (data: IUpdateWeightedProductSKU) => {
    this.updateWeightedProductSkuState.inProgress = true;
    let url = `${URLConstants.UpdateWeightedProductSku}`;
    return baseService
      .postRequest(url, data)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.updateWeightedProductSkuState.error = response.data.Message;
        } else {
          this.updateWeightedProductSkuState.success = true;
        }
      })
      .catch((err: string) => {
        this.updateWeightedProductSkuState.error = err;
      })
      .finally(
        action(() => {
          this.updateWeightedProductSkuState.inProgress = false;
        })
      );
  };

  resetUpdateWeightedProductState = () => {
    this.updateWeightedProductSkuState.inProgress = false;
    this.updateWeightedProductSkuState.success = false;
    this.updateWeightedProductSkuState.error = "";
  };

  resetExportFileState = () => {
    this.exportFileState = { ...this.initialStateValue };
  };

  ExportFileService = (tenantId: number, data: IWeightedProductFilter) => {
    this.exportFileState.inProgress = true;
    let url = `${URLConstants.ExportFile}/${tenantId}`;
    return baseService
      .postRequest(url, data)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.exportFileState.error = response.data.Message;
        } else {
          this.exportFileState.success = true;
          this.base64String = { fileString: response.data.Data };
        }
      })
      .catch((err: string) => {
        this.exportFileState.error = err;
      })
      .finally(
        action(() => {
          this.exportFileState.inProgress = false;
        })
      );
  };

  GetPendingWeightedProductsListService = (
    currentPage: number,
    pagerSize: number,
    tenantId: number,
    order: string,
    filter: string
  ) => {
    if (tenantId === -1) {
      this.weightedProductList = GetAllWeighedProductState;
      return;
    }

    this.weightedProductState.inProgress = true;
    const url =
      URLConstants.GetPendingWeightedProductsList +
      `/${tenantId}` +
      `?PageNo=${currentPage}` +
      `&PageSize=${pagerSize}` +
      `&order=${order}` +
      `&filter=${filter}`;

    return baseService
      .getRequest(url)
      .then(
        (
          response: IApiResponse<IApiSuccessResponse<IWeightedProductListVM>>
        ) => {
          if (response.data.Error) {
            this.error = response.data.Message;
            this.weightedProductState.error = response.data.Message;
            toast.error(formatMessage(response.data.Message));
          } else {
            this.weightedProductList = response.data.Data;
            this.weightedProductState.success = true;
          }
        }
      )
      .catch((err: string) => {
        this.weightedProductState.error = err;
        this.weightedProductList = GetAllWeighedProductState;
        toast.error(formatMessage("SomethingWentWrong"));
      })
      .finally(
        action(() => {
          this.weightedProductState.inProgress = false;
        })
      );
  };

  public GetPendingWeightedProductByIdService = (
    tenantId: number,
    id: number
  ) => {
    this.getPendingWeightedProductState.inProgress = true;
    let url = `${URLConstants.GetPendingWeightedProductById}/${tenantId}/${id}`;
    return baseService
      .getRequest(url)
      .then(
        (response: IApiResponse<IApiSuccessResponse<IWeightedProductVM>>) => {
          if (response.data.Error) {
            this.getPendingWeightedProductState.error = response.data.Message;
          } else {
            this.weightedProduct = response.data.Data;
          }
        }
      )
      .catch((err: string) => {
        this.getPendingWeightedProductState.error = err;
      })
      .finally(
        action(() => {
          this.getPendingWeightedProductState.inProgress = false;
        })
      );
  };

  UpdatePendingWeightedProductSku = (data: IUpdateWeightedProductSKU) => {
    this.updatePendingWeightedProductSkuState.inProgress = true;
    let url = `${URLConstants.UpdatePendingWeightedProductSku}`;
    return baseService
      .postRequest(url, data)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.updatePendingWeightedProductSkuState.error =
            response.data.Message;
        } else {
          this.updatePendingWeightedProductSkuState.success = true;
          // toast.success(formatMessage(response.data.Message));
        }
      })
      .catch((err: string) => {
        this.updatePendingWeightedProductSkuState.error = err;
      })
      .finally(
        action(() => {
          this.updatePendingWeightedProductSkuState.inProgress = false;
        })
      );
  };

  DeletePendingProductService = (id: number) => {
    this.deletePendingProductState.inProgress = true;
    let url = URLConstants.DeletePendingWeightedProduct + "/" + id;
    return baseService
      .deleteRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.deletePendingProductState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else this.deletePendingProductState.success = true;
      })
      .catch((err: string) => {
        this.deletePendingProductState.error = err;
      })
      .finally(
        action(() => {
          this.deletePendingProductState.inProgress = false;
        })
      );
  };
}

export default new ProductStore();
