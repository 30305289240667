import React, { useState } from "react";

const AddWeightedProductPopupHOC = (Component: any) =>
  function Comp(props: any) {
    const [showAddWeightedProductPopup, setShowAddWeightedProductPopup] = useState<boolean>(false);

    const addToggleHandler = () => {
      setShowAddWeightedProductPopup(!showAddWeightedProductPopup);
    };

    return (
      <Component
        showAddWeightedProductPopup={showAddWeightedProductPopup}
        addToggleHandler={addToggleHandler}
        {...props}
      />
    );
  };

export default AddWeightedProductPopupHOC;
