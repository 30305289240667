/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { observer } from "mobx-react";
import React, { SyntheticEvent, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FormattedMessage } from "react-intl";
import noMessageIcon from "../../assets/Images/find.png";
import { ModuleType } from "../../constants/enums/module-type-enum";
import { useStore } from "../../contexts/store-provider";
import { showIconsRoleBase } from "../../helpers/action-permission-helper";
import IPageInfo, {
  IActionFormatter,
  IPageLimitInfo,
} from "../../models/ICommon";
import Grid from "../../shared-components/Grid/Grid";
import Pagination from "../../shared-components/Grid/Pagination";
import DeletePopup from "../../shared-components/popup/delete/delete-popup";
import DeletePopupHOC from "../../shared-components/popup/delete/delete-popup-hoc";
import { formatMessage } from "../../translations/format-message";
import "./product.css";

import SlidingPanel from "react-sliding-side-panel";
import productSettingEnum from "../../constants/enums/product-setting-enum";
import { getTenantIdFromLocalStorage } from "../../helpers/localstorage-helper";
import { IWeightedProductFilter } from "../../models/IWeightedProductFilter";
import { CustomActionFormatter } from "../../shared-components/Grid/GridFormatter";
import ImagePopupHOC from "../../shared-components/popup/image-popup/image-popup-hoc";
import AddEditPendingWeightedProduct from "./components/add-edit-pending-weighted-product";
import AddWeightedProductPopupHOC from "./hoc/add-weighted-product-popup";
import FilterOrderPopupHOC from "./hoc/filter-order-popup-hoc";
import WeightedPendingProductDetails from "./weighted-pending-product-details";

const WeightedProductPending = (props: any) => {
  const { productStore, preferencesStore, authStore, tenantStore } = useStore();
  const { getUserType } = authStore;
  const {
    allWeightedProduct,
    deleteProductState,
    resetAddUpdateProductState,
    addUpdateProductState,
    resetDeleteProduct,
    selectedTenantId,
    setSelectedTenantId,
    resetProductDetail,
    weightedProductList,
    updateWeightedProductSkuState,
    resetUpdateWeightedProductState,
    resetWeightedProductDetail,
    ExportFileService,
    exportFileState,
    base64String,
    resetExportFileState,
    GetPendingWeightedProductsListService,
    getPendingWeightedProductState,
    updatePendingWeightedProductSkuState,
    weightedProductState,
    resetWeightedProductState,
    deletePendingProductState,
    DeletePendingProductService,
    resetDeletePendingProduct,
  } = productStore;

  const {
    productSetting,
    setProductSetting,
    language,
    islanguageChanged,
    updateLanguageChangedFlag,
  } = preferencesStore;

  const { GetAllTenants, allTenantOptions } = tenantStore;

  const [selectedId, setSelectedId] = useState<number>(0);
  let lang: string;
  const [disablePagination, setDisablePagination] = useState<boolean>(false);
  const allProductsList: Array<any> = allWeightedProduct;
  const [selectedRow, setSelectedRow] = useState<any>({});
  const pageLimitOptions: Array<number> = [10, 50, 100];
  const [openPanel, setOpenPanel] = useState(false);
  const [toggleRefreshBtn, setToggleRefreshBtn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sortColumn, setSortColumn] = useState("AddedDate");
  const [sortOrder, setSortOrder] = useState("desc");
  const [toggleAutoRefresh, setToggleAutoRefresh] = useState(false);
  const [filterString, setFilterString] = useState("");
  const [isFilterCleared, setIsFilterCleared] = useState(true);
  const [filterObj, setFilterObj] = useState<IWeightedProductFilter>({
    TenantId: -1,
    ProductName: "",
    Sku: "",
    ColumnOrder: "",
    StartDate: "",
    EndDate: "",
  });
  const [pageLimitInfo, setPageLimit] = useState<IPageLimitInfo>({
    pageLimit: productSetting.pageLimitConfig,
    isPageLimitChange: false,
  });

  const [pageInfo, setPageInfo] = useState<IPageInfo>({
    currentPage: 1,
    totalPages: 1,
    isPagerChange: false,
    isRefreshPage: false,
  });

  const onPageChanged = (pageNumber: number) => {
    setPageInfo({ ...pageInfo, currentPage: pageNumber, isPagerChange: true });
    closePanelhandler();
  };

  const setIsRefreshPage = () => {
    setPageInfo({ ...pageInfo, isRefreshPage: false });
  };

  const onPageLimitChanged = (evt: any) => {
    let pages: number;
    pages = props.data && Math.ceil(props.data.length / evt.target.value);
    setPageInfo({
      ...pageInfo,
      currentPage: 1,
      totalPages: pages,
      isPagerChange: false,
    });
    setPageLimit({
      pageLimit: parseInt(evt.target.value),
      isPageLimitChange: true,
    });
    setProductSetting(
      productSettingEnum.ProductLimitConfig,
      parseInt(evt.target.value)
    );
  };

  const getProductListServiceHandler = (
    order = "added_date DESC",
    filter = ""
  ) => {
    resetWeightedProductState();
    if (Number(getTenantIdFromLocalStorage()) >= 0) {
      GetPendingWeightedProductsListService(
        pageInfo.currentPage,
        pageLimitInfo.pageLimit,
        Number(getTenantIdFromLocalStorage()),
        order,
        filter
      );
    } else {
      GetPendingWeightedProductsListService(
        pageInfo.currentPage,
        pageLimitInfo.pageLimit,
        selectedTenantId,
        order,
        filter
      );
    }
  };

  const handleExport = (base64Data: string) => {
    // Decode the base64 string
    const binaryData = atob(base64Data);
    const binaryArray = new Uint8Array(binaryData.length);

    // Fill the binary array with the decoded binary data
    for (let i = 0; i < binaryData.length; i++) {
      binaryArray[i] = binaryData.charCodeAt(i);
    }

    // Create a Blob from the binary data with the MIME type for Excel
    const blob = new Blob([binaryArray], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Create a URL for the Blob and set up the download link
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `ProductsExportFile-${Date.now()}.xlsx`;

    // Trigger the download by simulating a click on the link
    document.body.appendChild(link);
    link.click();

    // Clean up and remove the link after download
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const getTenantsListServiceHandler = () => {
    if (getTenantIdFromLocalStorage() === "-1") {
      GetAllTenants();
    }
  };

  function handleTenantChange(value: any) {
    closePanelhandler();
    setSelectedTenantId(value);
    setIsLoading(true);
  }

  //Refresh automatically after 10 seconds
  useEffect(() => {
    // if (toggleAutoRefresh) {
    getTenantsListServiceHandler();
    const interval = setInterval(() => {
      getProductListServiceHandler(
        `${getFieldName(sortColumn)} ${sortOrder}`,
        filterString
      );
    }, 15000);

    return () => {
      // setToggleRefreshBtn(false);
      return clearInterval(interval);
    };
    // }
  }, [toggleAutoRefresh, pageInfo]);

  useEffect(() => {
    getTenantsListServiceHandler();
    setIsLoading(false);
  }, [isLoading]);

  useEffect(() => {
    getProductListServiceHandler(
      `${getFieldName(sortColumn)} ${sortOrder}`,
      filterString
    );
  }, [getPendingWeightedProductState.success]);

  useEffect(() => {
    if (deletePendingProductState.success) {
      toast.success(formatMessage("deleted_success"));
      props.deletePopupToggleHandler();
      resetDeletePendingProduct();
      getProductListServiceHandler(
        `${getFieldName(sortColumn)} ${sortOrder}`,
        filterString
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletePendingProductState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "language" of the useEffect changed.
   */
  useEffect(() => {
    if (islanguageChanged) {
      getProductListServiceHandler(
        `${getFieldName(sortColumn)} ${sortOrder}`,
        filterString
      );
      updateLanguageChangedFlag();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [islanguageChanged]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "GroupList" of the useEffect changed.
   */
  useEffect(() => {
    setPageInfo({
      ...pageInfo,
      totalPages: weightedProductList?.PagingDetails?.TotalPages,
      currentPage: weightedProductList?.PagingDetails?.CurrentPage,
      isPagerChange: false,
    });

    setDisablePagination(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weightedProductList]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "pageInfo.currentPage" of the useEffect changed.
   */
  useEffect(() => {
    if (pageInfo.isPagerChange) {
      getProductListServiceHandler(
        `${getFieldName(sortColumn)} ${sortOrder}`,
        filterString
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageInfo.currentPage]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "addUpdateGroupstate.success" of the useEffect changed.
   */
  useEffect(() => {
    if (addUpdateProductState.success) {
      resetAddUpdateProductState();
      getProductListServiceHandler(
        `${getFieldName(sortColumn)} ${sortOrder}`,
        filterString
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addUpdateProductState.success]);

  useEffect(() => {
    if (updateWeightedProductSkuState.success) {
      resetUpdateWeightedProductState();
      getProductListServiceHandler(
        `${getFieldName(sortColumn)} ${sortOrder}`,
        filterString
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateWeightedProductSkuState.success]);

  useEffect(() => {
    if (weightedProductState.success) {
      setToggleRefreshBtn(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weightedProductState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "deleteProductSuccess" of the useEffect changed.
   */
  useEffect(() => {
    if (deleteProductState.success) {
      toast.success(formatMessage("deleted_success"));
      props.deletePopupToggleHandler();
      resetDeleteProduct();
      getProductListServiceHandler(
        `${getFieldName(sortColumn)} ${sortOrder}`,
        filterString
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteProductState.success]);

  const openAddProductHandler = (
    row: { Id: number },
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    closePanelhandler();
    resetProductDetail();
    setSelectedId(row.Id);
    props.addToggleHandler();
  };

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "selectedTenantId" of the useEffect changed.
   */
  useEffect(() => {
    getProductListServiceHandler(
      `${getFieldName(sortColumn)} ${sortOrder}`,
      filterString
    );
    // if (selectedTenantId >= 0) {
    // }
  }, [selectedTenantId]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "pageLimitInfo.pageLimit" of the useEffect changed.
   */
  useEffect(() => {
    if (pageLimitInfo.isPageLimitChange) {
      getProductListServiceHandler(
        `${getFieldName(sortColumn)} ${sortOrder}`,
        filterString
      );
      setPageLimit({ ...pageLimitInfo, isPageLimitChange: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageLimitInfo.pageLimit]);

  /**
   * This function deletes the product by providing Id to the Api
   */
  const deleteHandler = () => {
    DeletePendingProductService(selectedId);
  };

  const filterHandler = (filter: string) => {
    getProductListServiceHandler("added_date DESC", encodeURIComponent(filter));
    setFilterString(encodeURIComponent(filter));
    closePanelhandler();
    props.filterOrderPopupToggleHandler();
    setIsFilterCleared(false);
  };

  const filterParams = (
    obj: IWeightedProductFilter,
    isfilterCleard: boolean
  ) => {
    if (isfilterCleard) {
      setFilterObj({
        ...obj,
        EndDate: null,
        StartDate: null,
        ColumnOrder: `${getFieldName(sortColumn)}  ${sortOrder}`,
        TenantId: -1,
      });
      getProductListServiceHandler("added_date DESC");
      closePanelhandler();
      setIsFilterCleared(true);
      setFilterString("");
    } else {
      setFilterObj({
        ...obj,
        ColumnOrder: `${getFieldName(sortColumn)}  ${sortOrder}`,
        TenantId:
          getTenantIdFromLocalStorage() === "-1"
            ? Number(selectedTenantId)
            : Number(getTenantIdFromLocalStorage()),
      });
    }
    props.filterOrderPopupToggleHandler();
  };

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "language" of the useEffect changed.
   */
  useEffect(() => {
    if (language !== lang) {
      getProductListServiceHandler(
        `${getFieldName(sortColumn)} ${sortOrder}`,
        filterString
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  /**
   * This function provides manual refresh functionality
   */
  const refreshClickHandler = () => {
    setToggleRefreshBtn(true);
    getProductListServiceHandler();
    closePanelhandler();
    setSortColumn("AddedDate");
    setSortOrder("desc");
    setFilterObj({
      TenantId: -1,
      ProductName: "",
      Sku: "",
      ColumnOrder: "",
      StartDate: "",
      EndDate: "",
    });
  };

  function rowClassFormat(row: any, rowIdx: number) {
    let className: string = "";
    if (selectedRow?.Id && row.Id === selectedRow.Id) {
      className = "bg-row SelectedRow";
    }
    return className;
  }

  const rowEvents = (e: SyntheticEvent, row: any) => {
    setSelectedRow(row);
    if (selectedRow.Id === undefined || selectedRow.Id !== row.Id) {
      setOpenPanel(true);
    } else {
      setOpenPanel(!openPanel);
    }
    resetWeightedProductDetail();
  };

  const closePanelhandler = () => {
    setSelectedRow({});
    setOpenPanel(false);
    resetWeightedProductDetail();
  };

  useEffect(() => {
    if (exportFileState.success && base64String.fileString.length > 0) {
      handleExport(base64String.fileString);
      resetExportFileState();
    }
  }, [exportFileState.success]);

  useEffect(() => {
    if (updatePendingWeightedProductSkuState.success) {
      getProductListServiceHandler();
      // toast.success(formatMessage("updated_success"));
      // resetAddUpdatePendingroductState();
      // onModalClosed();
    }
  }, [updatePendingWeightedProductSkuState.success]);

  const exportFiletHandler = () => {
    ExportFileService(
      getTenantIdFromLocalStorage() === "-1"
        ? Number(selectedTenantId)
        : Number(getTenantIdFromLocalStorage()),
      {
        ...filterObj,
        StartDate:
          filterObj.StartDate?.length === 0 ? null : filterObj.StartDate,
        EndDate: filterObj.EndDate?.length === 0 ? null : filterObj.EndDate,
      }
    );
  };

  const deleteClickHandler = (
    row: any,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setSelectedId(row.Id);
    event.stopPropagation();
    closePanelhandler();
    resetProductDetail();
    props.deletePopupToggleHandler();
  };

  const actionIcons: IActionFormatter[] = showIconsRoleBase(
    [
      {
        dataField: "isEdit",
        handler: openAddProductHandler,
        icon: (
          <svg
            id="Layer_2"
            fill="#2b4c21"
            width="21"
            height="21"
            data-name="Layer 2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 28.43 24.75"
          >
            <g id="_レイヤー_3" data-name="レイヤー 3">
              <g>
                <path d="M10.53,15.53l1.19,1.19,1.19,1.19-1.85,.58-1.61,.5,.5-1.61,.58-1.85m-.45-1.81l-1.05,3.37-1.05,3.37,3.37-1.05,3.37-1.05-2.32-2.32-2.32-2.32h0Z" />
                <polygon points="7.97 20.46 8.57 18.54 9.17 16.62 10.49 17.94 11.81 19.26 9.89 19.86 7.97 20.46" />
                <path d="M15.22,19.37l-6.15-6.15L22.28,0l6.15,6.15-13.21,13.21Zm-3.12-6.15l3.12,3.12L25.4,6.15l-3.12-3.12L12.1,13.21Z" />
                <path d="M23.57,24.75H4.47c-2.46,0-4.47-2-4.47-4.47v-6.38c0-2.46,2-4.47,4.47-4.47h2.36v2.24h-2.36c-1.23,0-2.23,1-2.23,2.23v6.38c0,1.23,1,2.23,2.23,2.23H23.57c1.23,0,2.23-1,2.23-2.23v-7.47h2.24v7.47c0,2.46-2,4.47-4.47,4.47Z" />
                <rect
                  x="13.01"
                  y="8.8"
                  width="11.25"
                  height="1.3"
                  transform="translate(-1.22 15.95) rotate(-45)"
                />
              </g>
            </g>
          </svg>
        ),
        isDisable: false,
        title: "edit",
        action: "edit",
        varient: "primary",
      },
      {
        dataField: "isDelete",
        handler: deleteClickHandler,
        icon: (
          <svg
            id="Layer_2"
            fill="#0091ff"
            width="18"
            height="18"
            data-name="Layer 2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20.09 20.66"
          >
            <g id="_レイヤー_3" data-name="レイヤー 3">
              <g>
                <polygon points="12.12 1.99 12.12 0 10.05 0 7.97 0 7.97 1.99 0 1.99 0 4.07 10.05 4.07 20.09 4.07 20.09 1.99 12.12 1.99" />
                <path d="M14.29,17.76c-.02,.37-.32,.66-.69,.66H6.49c-.37,0-.67-.29-.69-.65l-.53-12.44-2.24,.09,.53,12.45c.08,1.56,1.37,2.78,2.93,2.78h7.11c1.56,0,2.85-1.22,2.93-2.79l.53-12.44-2.24-.09-.53,12.43Z" />
                <rect x="11.68" y="6.8" width="1.16" height="9.8" />
              </g>
            </g>
          </svg>
        ),
        isDisable: false,
        title: "delete",
        varient: "danger",
        action: "delete",
        hidden: false || getTenantIdFromLocalStorage() === "-1",
      },
    ],
    ModuleType.ProductManagement
  );

  /**
   * The column constant defines the column description for the product grid (table)
   */
  const columns: any = [
    {
      dataField: "Id",
      isDummyField: true,
      formatter: (_: any, __: any, rowIndex: number) => rowIndex + 1,
      classes: "address-Tenant",
      text: formatMessage("s_no"),
      title: (cell: string) => {
        return cell?.length > 10 ? cell : undefined;
      },
    },
    {
      dataField: "Sku",
      text: formatMessage("SKU"),
      classes: "address-Tenant",
      title: (cell: string) => {
        return cell?.length > 10 ? cell : undefined;
      },
      sort: true,
      sortFunc: (a: string, b: string, order: "asc" | "desc") => {
        const comparison = a.localeCompare(b);
        return order === "asc" ? comparison : -comparison;
      },
    },
    {
      dataField: "Weight",
      text: formatMessage("weight"),
      classes: "address-Tenant",
      title: (cell: string) => {
        return cell?.length > 10 ? cell : undefined;
      },
    },
    {
      dataField: "AddedDate",
      text: formatMessage("added_date"),
      classes: "address-Tenant",
      title: (cell: string) => {
        return cell?.length > 10 ? cell : undefined;
      },
      sort: true,
      sortFunc: (
        a: string,
        b: string,
        order: "asc" | "desc",
        dataField: any,
        rowA: any,
        rowB: any
      ) => {
        const dateA = new Date(rowA[dataField]);
        const dateB = new Date(rowB[dataField]);

        if (order === "asc") {
          return dateA.getTime() - dateB.getTime();
        }
        return dateB.getTime() - dateA.getTime();
      },
    },
    {
      dataField: "",
      formatter: CustomActionFormatter(actionIcons),
      text: formatMessage("actions"),
      classes: "last-column",
      hidden: actionIcons.length > 0 ? false : true,
    },
  ];

  function getFieldName(field: string): string {
    switch (field) {
      case "Name":
        return "name";
      case "Sku":
        return "sku";
      case "AddedDate":
      default:
        return "added_date";
    }
  }

  const handleTableChange = (type: any, newState: any) => {
    setFilterObj({
      ...filterObj,
      ColumnOrder: `${getFieldName(newState.sortField)} ${newState.sortOrder}`,
    });
    setSortColumn(newState.sortField);
    setSortOrder(newState.sortOrder);
    getProductListServiceHandler(
      `${getFieldName(newState.sortField)} ${newState.sortOrder}`,
      filterString
    );
  };

  return (
    <React.Fragment>
      {props.showAddWeightedProductPopup && (
        <AddEditPendingWeightedProduct
          id={selectedId}
          modalClosed={props.addToggleHandler}
        />
      )}

      {props.showDeletePopup && (
        <DeletePopup
          title="delete_product"
          modalSubmit={deleteHandler}
          modalClosed={props.deletePopupToggleHandler}
          message="message_delete_product_confirm"
          isLoading={deleteProductState.inProgress}
        />
      )}

      {/* {props.showfilterOrderPopup && (
        <FilterComponent
          initialValues={{
            StartDate: filterObj.StartDate === null ? "" : filterObj.StartDate,
            EndDate: filterObj.EndDate === null ? "" : filterObj.EndDate,
            Name: filterObj.ProductName,
            Sku:
              filterObj.Sku.length > 0
                ? filterObj.Sku.replace(/'/g, "").split(",").join(", ")
                : "",
          }}
          id={0}
          submitHandler={filterHandler}
          filterParmas={filterParams}
          onModalClosed={props.filterOrderPopupToggleHandler}
          isLoading={false}
        />
      )} */}

      <div className="icon-nav navbar-fixed-top device-nav-bar productTopNav">
        <div className="row align-items-center">
          <div className="col-md-5 col-12">
            <div className="page_title_wrap">
              <h1 className="page_title">
                <FormattedMessage id="weigheted_products_pending" />
              </h1>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <FormattedMessage id="Home" />
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  <FormattedMessage id="weigheted_products_pending" />
                </li>
              </ol>
            </div>
          </div>
          <div className="col-md-7 col-12">
            <ul className="add-list">
              <li>
                {getUserType === "SuperAdministrator" && (
                  <select
                    className="custom-select form-control me-2"
                    value={selectedTenantId}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      let value = parseInt(e.target.value);
                      handleTenantChange(value);
                    }}
                  >
                    {allTenantOptions.map((data: any, i: number) => (
                      <option key={i} value={data.id}>
                        {i == 0 ? formatMessage(data.value) : data.value}
                      </option>
                    ))}
                  </select>
                )}
              </li>

              <li title={formatMessage("refresh")} className="d-flex">
                <a
                  data-toggle="modal"
                  className={`btn btn-light ${
                    toggleRefreshBtn ? " disable " : " "
                  }`}
                  onClick={() => refreshClickHandler()}
                >
                  <svg
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-arrow-repeat m-0"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                    <path
                      fillRule="evenodd"
                      d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"
                    />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className={`${openPanel ? "panelOpen" : ""}`}>
        <div className="card ">
          {allProductsList.length > 0 ? (
            <>
              <div className="tableDesign row-eq-height tableDevices changingPanel p-3">
                <div className="table-responsive">
                  {allProductsList && (
                    <Grid
                      data={allProductsList}
                      columns={columns}
                      keyField="Id"
                      loading={false}
                      rowClasses={rowClassFormat}
                      rowEvents={{ onClick: rowEvents }}
                      remote={{
                        filter: false,
                        pagination: false,
                        sort: true,
                        cellEdit: false,
                      }}
                      onTableChange={handleTableChange}
                      sort={{
                        dataField: sortColumn,
                        order: sortOrder,
                      }}
                    />
                  )}
                </div>
              </div>
              {allProductsList.length > 0 ? (
                <div className="row no-gutters p-3 tableFooter">
                  <div className="col-sm-6 pt-1">
                    <FormattedMessage id="show" />
                    <select
                      data-testid="pageSelect"
                      className="pageLimit"
                      onChange={onPageLimitChanged}
                      value={pageLimitInfo.pageLimit}
                    >
                      {pageLimitOptions.map((op: any) => (
                        <option key={op} value={op}>
                          {op}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-sm-6 d-flex justify-content-sm-end">
                    <Pagination
                      isRefreshPage={false}
                      totalPages={pageInfo.totalPages}
                      pageLimit={pageLimitInfo.pageLimit}
                      onPageChanged={onPageChanged}
                      disablePagination={disablePagination}
                      setIsRefreshPage={setIsRefreshPage}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
            </>
          ) : (
            <div className="message">
              <div className="row text-center">
                <div className="col-lg-4">
                  <img src={noMessageIcon} alt="" className="no_message" />
                </div>
                <div className="col-lg-8 mt-5 p-3">
                  <h3 className="fw-bold display-6">
                    {" "}
                    <FormattedMessage id="no_data" />
                  </h3>
                  <h4 className="mb-3">
                    {formatMessage("no_product_data_message")}
                  </h4>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <SlidingPanel
        type={"right"}
        isOpen={openPanel}
        noBackdrop={false}
        size={100}
      >
        <div className="bg_slide panel">
          <div className="row bb p-2 pl-4 g-0 align-items-center ">
            <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10">
              <h2 className="fw-bold mb-0 mt-1 ms-2 py-2">
                {selectedRow.Name}
              </h2>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 close-icon-detail">
              <span title={formatMessage("close")}>
                <svg
                  onClick={closePanelhandler}
                  role="button"
                  xlinkTitle="close"
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="currentColor"
                  className="bi bi-x ms-auto d-block"
                  viewBox="0 0 16 16"
                >
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
              </span>
            </div>
          </div>
          <div className="group_detailsWrapper pb-3">
            <WeightedPendingProductDetails selectedDataId={selectedRow.Id} />
          </div>
        </div>
      </SlidingPanel>
    </React.Fragment>
  );
};

export default FilterOrderPopupHOC(
  AddWeightedProductPopupHOC(
    ImagePopupHOC(DeletePopupHOC(observer(WeightedProductPending)))
  )
);
