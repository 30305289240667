/*
<summary>
   This File contains all the routes constant.
   Developer:Mohammad Saquib Khan, Created Date:04-April-2024
</summary>
*/

import config from "../../helpers/config-helper";

const appConfig = config();
// const baseURL = appConfig.REACT_APP_PUBLIC_URL;
const baseURL = appConfig.REACT_APP_PUBLIC_URL + '/KMS';
const RoutesConstants = {
  Login: baseURL + '/Login',
  Logout: baseURL + '/Logout',
  KmsUser: baseURL + '/KmsUser',
  SystemUser: baseURL + '/SystemUser',
  TenantManagement: baseURL + '/TenantManagement',
  BranchManagement: baseURL + '/BranchManagement',
  MediaManagement: baseURL + '/MediaManagement',
  ParkingLotManagement: baseURL + '/ParkingLotManagement',
  IntegratorManagement: baseURL + '/IntegratorManagement',
  CameraManagement: baseURL + "/CameraManagement",
  FoldersManagement: baseURL + "/ManageFolders",
  PriceScheduler: baseURL + "/PriceScheduler",
  UserLogs: baseURL + "/UserLogs",
  SystemLogs: baseURL + "/SystemLogs",
  Products: baseURL + "/Products",
  WeightedProducts: baseURL + "/WeightedProducts",
  AccountActivation: baseURL + "/AccountActivation",
  PendingProducts: baseURL + "/PendingProducts",
};
export default RoutesConstants;