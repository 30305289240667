

/*
<summary>
  This functional component shows the selected Weighted Product Details.
  Developer:Raman Sharma, Created Date: 28-August-2024
</summary>
<returns>Returns JSX</returns>
*/

import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import { useStore } from "../../contexts/store-provider";
import { getTenantIdFromLocalStorage } from "../../helpers/localstorage-helper";
import ImagePopup from "../../shared-components/popup/image-popup/image-popup";
import ImagePopupHOC from "../../shared-components/popup/image-popup/image-popup-hoc";

interface IProps {
  addToggleHandler: any;
  showImagePopup: boolean;
  selectedDataId: number;
  imageShowToggleHandler: any;
}

const WeightedPendingProductDetails = (props: IProps) => {
  const [openPanel, setOpenPanel] = useState(false);
  const { productStore } = useStore();
  const {
    GetWeightedProductByIdService,
    weightedProductDetail,
    selectedTenantId,
    GetPendingWeightedProductByIdService,
    getPendingWeightedProductState
  } = productStore;

  const productDetails: any = weightedProductDetail;
  const viewImageHandler = (image: string,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    closePanelhandler();
    props.imageShowToggleHandler();
  };

  const closePanelhandler = () => {
    setOpenPanel(false);
  };

  useEffect(() => {
    if (props.selectedDataId > 0) {
      GetPendingWeightedProductByIdService(
        getTenantIdFromLocalStorage() === "-1"
          ? selectedTenantId
          : Number(getTenantIdFromLocalStorage()),
        props.selectedDataId,
      );
    }
  }, [props.selectedDataId]);


  return (
    <React.Fragment>
      {
        props.showImagePopup
        && (
          <ImagePopup
            image={productDetails.Image}
            modalClosed={props.imageShowToggleHandler}
            isLoading={false}
          />
        )
      }
      <div className="px-4  py-3">
        <div className="row">
          <div className="col-lg-6">
            <h3 className="m-0"><FormattedMessage id={"display_name"} /></h3>
          </div>
          <div className="col-lg-6">
            <p className="mw-100 mb-0 text-muted text-end">{productDetails.Name}</p>
          </div>
        </div>

        <hr />
        <div className="row">
          <div className="col-lg-6">
            <h3 className="m-0"><FormattedMessage id={"Sku"} />{" "}</h3>
          </div>
          <div className="col-lg-6">
            <p className="mw-100 mb-0 text-muted text-end"> {productDetails.Sku}</p>
          </div>
        </div>
        <hr />

        <div className="row">
          <div className="col-lg-6">
            <h3 className="m-0"><FormattedMessage id={"WeightUnit"} />{" "}</h3>
          </div>
          <div className="col-lg-6">
            <p className="mw-100 mb-0 text-muted text-end"> {productDetails.WeightUnit}</p>
          </div>
        </div>

        <hr />
        <div className="row">
          <div className="col-lg-6">
            <h3 className="m-0"><FormattedMessage id={"weight"} /></h3>
          </div>
          <div className="col-lg-6"> <p className="mw-100 mb-0 text-muted text-end">  {productDetails.Weight}</p>
          </div>
        </div>

        <hr />
        <div className="row">
          <div className="col-lg-6">
            <h3 className="m-0"><FormattedMessage id={"added_date"} /></h3>
          </div>
          <div className="col-lg-6"> <p className="mw-100 mb-0 text-muted text-end">  {productDetails.AddedDate}</p>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-12 mt-2">
            <div className="product-images">
              <img src={`data:image/jpeg;base64,${productDetails.Image}`} alt="Product Image" className="productImages img-fluid" />
            </div>
            <div className="open-image-window text-center mt-1">
              {
                productDetails.Image && <button
                  onClick={(event: any) => viewImageHandler(productDetails.Image, event)}
                  type="button"
                  className="btn btn-primary">
                  <svg fill="#fff" height="20" className="me-1" viewBox="0 0 16 16" width="20" xmlns="http://www.w3.org/2000/svg">
                    <g clipRule="evenodd" fill="#fff" fillRule="evenodd"><path d="m2 5.31818c0-1.28077 1.03803-2.31818 2.31818-2.31818h2.02818c.27615 0 .5.22386.5.5s-.22385.5-.5.5h-2.02818c-.72803 0-1.31818.58986-1.31818 1.31818v6.36362c0 .7283.59015 1.3182 1.31818 1.3182h6.36362c.728 0 1.3182-.5899 1.3182-1.3182v-2.02725c0-.27615.2239-.5.5-.5s.5.22385.5.5v2.02725c0 1.2808-1.038 2.3182-2.3182 2.3182h-6.36362c-1.28015 0-2.31818-1.0374-2.31818-2.3182z" /><path d="m9 2.5c0-.27614.22386-.5.5-.5h4c.2761 0 .5.22386.5.5v4c0 .27614-.2239.5-.5.5s-.5-.22386-.5-.5v-3.5h-3.5c-.27614 0-.5-.22386-.5-.5z" /><path d="m13.4891 2.52702c.195.19558.1945.51216-.0011.70711l-4.63502 4.62c-.19558.19494-.51216.19443-.70711-.00115-.19494-.19558-.19443-.51216.00115-.70711l4.63498-4.62c.1956-.19494.5122-.19443.7071.00115z" />
                    </g>
                  </svg>
                  View Image
                </button>
              }
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ImagePopupHOC(observer(WeightedPendingProductDetails));
