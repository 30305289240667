/*
<summary>
   This functional common components provides us custom buttons for grid.
   Developer:Mohammad Saquib Khan, Created Date:31-March-2024
</summary>
<returns>Returns JSX</returns>
*/
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Grid.css";
import { formatMessage } from "../../translations/format-message";
import { IActionFormatter } from "../../models/ICommon";
import config from "../../helpers/config-helper";
import { getTenantIdFromLocalStorage } from "../../helpers/localstorage-helper";

const appConfig = config();
const dateTimeFormat = appConfig.REACT_APP_DATE_TIME_FORMAT;

export const ActionFormatter =
  (actions: Array<IActionFormatter>) => (cell: any, row: any) => {
    return (
      <div className="d-flex">
        {actions.map((action: IActionFormatter, index: number) => {
          return (
            <button
              className={`btn btn-light-${action.varient} `}
              key={index}
              title={formatMessage(action.title)}
              type="button"
              disabled={action.isDisable && !row[action.dataField]}
              onClick={(event) => action.handler(row, event)}
            >
              <FontAwesomeIcon icon={action.icon} />
            </button>
          );
        })}
      </div>
    );
  };

export const CustomActionFormatter =
  (actions: Array<IActionFormatter>) => (cell: any, row: any) => {
    return (
      <>
        <div className="d-flex">
          {actions.map((action: IActionFormatter, index: number) => {
            return (
              <button
                className={`btn btn-light-${action.varient} btn-sm ${
                  action.hidden ? " d-none " : ""
                }`}
                key={index}
                title={formatMessage(action.title)}
                type="button"
                disabled={action.isDisable && !row[action.dataField]}
                onClick={(event) => action.handler(row, event)}
              >
                {action.icon}
              </button>
            );
          })}
        </div>
      </>
    );
  };
