/*
<summary>
   This typescript ts file contain all the formik form validations.
   Developer:Aashish Singh, Created Date:29-March-2023
</summary>
*/
import * as Yup from "yup";
import config from "../../helpers/config-helper";
import URLConstants from "../../constants/url-constants";
import * as baseService from "../../core/service/base-service";

const appConfig = config();

const macAddressRegex = /^([0-9A-Fa-f]{2}([-:])){5}[0-9A-Fa-f]{2}$/;
const ipv4Regex =
  /^(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])$/;
const ipv6Regex =
  /^(([0-9a-fA-F]{1,4}:){7}([0-9a-fA-F]{1,4}|:)|(([0-9a-fA-F]{1,4}:){1,6}|:):([0-9a-fA-F]{1,4}|:){1,5}([0-9a-fA-F]{1,4}|:)|::)$/;

const ipRegex = new RegExp(`(${ipv4Regex.source})|(${ipv6Regex.source})`);

const defaultPasswordValidation = {
  DefaultPassword: Yup.string()
    .min(8, "min_length_required")
    .max(20, "min_length_required")
    .matches(
      /^(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/,
      // /^(?=.*[A-Z])(?=.*\d).{8,}$/,
      "user_validation_password_matches"
    )
    .required("field_required"),
};

export const ChangeLoggedInUserPasswordValidateSchema = Yup.object({
  OldPassword: Yup.string()
    .min(6, "user_validation_password_min_char")
    .max(20, "user_validation_password_max_char")
    .required("validation_required_current_password"),
  NewPassword: Yup.string()
    .min(6, "user_validation_password_min_char")
    .max(20, "user_validation_password_max_char")
    .required("change_password_validation_new_required_password"),
});

export const UserChangePasswordValidateSchema = Yup.object({
  NewPassword: Yup.string()
    .min(8, "min_length_required")
    .max(20, "min_length_required")
    .matches(
      /^(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/,
      "user_validation_password_matches"
    )
    .required("field_required"),
  ConfirmPassword: Yup.string()
    .min(8, "min_length_required")
    .max(20, "min_length_required")
    .matches(
      /^(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/,
      "user_validation_password_matches"
    )
    .required("field_required")
    .test("different-password", "same_password_message", function (value) {
      return value === this.parent.NewPassword;
    }),
});

export const LoginValidateSchema = Yup.object({
  email: Yup.string().trim().required("field_required"),
  password: Yup.string().trim().required("field_required"),
});

export const AddUserValidateSchema = Yup.object({
  UserName: Yup.string()
    .min(1, "min_user_name_length_required")
    .max(25, "min_user_name_length_required")
    .required("field_required")
    .test("no-leading-whitespace", "whitespace_error", (value) => {
      if (typeof value !== "string") return false;
      return !value.startsWith(" ");
    }),
  Password: Yup.string()
    .min(8, "min_length_required")
    .max(20, "min_length_required")
    .matches(
      /^(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/,
      "user_validation_password_matches"
    )
    .required("field_required"),
  Email: Yup.string()
    .matches(
      /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([一-龠ぁ-ゔァ-ヴー々〆〤a-zA-Z\-0-9]+\.)+[一-龠ぁ-ゔァ-ヴー々〆〤a-zA-Z]{2,}))$/,
      "invalid_login_id"
    )
    .min(5, "lodin_id_length_required")
    .max(50, "lodin_id_length_required")
    .required("field_required"),
  UserType: Yup.number().min(0, "field_required"),
});

export const EditUserValidateSchema = Yup.object({
  UserName: Yup.string()
    .required("field_required")
    .min(1, "min_user_name_length_required")
    .max(25, "min_user_name_length_required"),
  Email: Yup.string()
    .matches(
      /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([一-龠ぁ-ゔァ-ヴー々〆〤a-zA-Z\-0-9]+\.)+[一-龠ぁ-ゔァ-ヴー々〆〤a-zA-Z]{2,}))$/,
      "invalid_login_id"
    )
    .min(5, "login_id_length_required")
    .max(50, "login_id_length_required")
    .required("field_required")
    .email(),
  UserType: Yup.number().min(0, "field_required"),
});

export const AddSystemUserValidateSchema = Yup.object({
  UserName: Yup.string()
    .min(1, "min_user_name_length_required")
    .max(25, "min_user_name_length_required")
    .required("field_required"),
  Password: Yup.string()
    .min(8, "min_length_required")
    .max(20, "min_length_required")
    .matches(
      /^(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/,
      "user_validation_password_matches"
    )
    .required("field_required"),
  ConfirmPassword: Yup.string().test(
    "different-password",
    "confirm_password_match",
    function (value) {
      return value === this.parent.Password;
    }
  ),
  Email: Yup.string()
    .matches(
      /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([一-龠ぁ-ゔァ-ヴー々〆〤a-zA-Z\-0-9]+\.)+[一-龠ぁ-ゔァ-ヴー々〆〤a-zA-Z]{2,}))$/,
      "invalid_login_id"
    )
    .min(5, "lodin_id_length_required")
    .max(50, "lodin_id_length_required")
    .required("field_required")
    .email()
    .test("email check", "EmailAlreadyExists", async (value) => {
      const x: any = await baseService.getRequest(
        URLConstants.VerifyUserEmail + `?userEmail=${value}`
      );
      return x.data.Message !== "EmailAlreadyExists";
    }),
  TenantId: Yup.number().min(0, "field_required"),
  UserType: Yup.number().min(0, "field_required"),
});

export const AddImageMediaValidateSchema = Yup.object({
  ImageName: Yup.string()
    .required("field_required")
    .max(20, "char_limit_reached"),
});

export const UpdateImageMediaValidateSchema = Yup.object({
  Height: Yup.number()
    .min(0, "height_max_min")
    .max(20000, "height_max_min")
    .required("field_required"),
  Width: Yup.number()
    .min(0, "width_max_min")
    .max(20000, "width_max_min")
    .required("field_required"),
});

export const UpdateImagePricingMediaValidateSchema = Yup.object({
  PricingValue: Yup.number()
    .typeError("only_digits")
    .min(0, "pricing_range")
    .max(9, "pricing_range")
    .required("field_required"),
});

export const EmptyValidateSchema = Yup.object({});

export const UpdateVideoValidateSchema = Yup.object({
  Height: Yup.number()
    .min(0, "height_max_min")
    .max(20000, "height_max_min")
    .required("field_required"),
  Width: Yup.number()
    .min(0, "width_max_min")
    .max(20000, "width_max_min")
    .required("field_required"),
});

export const DigitMappingValidateSchema = Yup.object({
  DataNumber: Yup.array().of(
    Yup.object().shape({
      FolderId: Yup.number(),
      Height: Yup.number()
        .min(0, "height_max_min")
        .max(20000, "height_max_min")
        .when("FolderId", (FolderId, schema) => {
          if (FolderId[0] > 0) {
            return schema.required("field_required");
          } else {
            return schema.notRequired();
          }
        }),
      // Height: Yup.number()
      // .min(0, "height_max_min").max(20000, "height_max_min")
      // .required("field_required"),
      Width: Yup.number()
        .min(0, "width_max_min")
        .max(20000, "width_max_min")
        .when("FolderId", (FolderId, schema) => {
          if (FolderId[0] > 0) {
            return schema.required("field_required");
          } else {
            return schema.notRequired();
          }
        }),
    })
  ),
});

export const uploadImageSchema = Yup.object({
  ImageFile: Yup.string().required("field_required"),
});

export const AddPriceSchedulerSchema = Yup.object({
  Weekday: Yup.number().min(0, "field_required"),
  Data1: Yup.string()
    .matches(/^[0-9\s]*$/, "only_digits")
    .test("maxDigits", "data_digits_valid", (value) => {
      if (value === undefined) {
        return true;
      }
      return value.toString().length <= 4;
    }),
  Data2: Yup.string()
    .matches(/^[0-9\s]*$/, "only_digits")
    .test("maxDigits", "data_digits_valid", (value) => {
      if (value === undefined) {
        return true;
      }
      return value.toString().length <= 4;
    }),
  Data3: Yup.string()
    .matches(/^[0-9\s]*$/, "only_digits")
    .test("maxDigits", "data_digits_valid", (value) => {
      if (value === undefined) {
        return true;
      }
      return value.toString().length <= 4;
    }),
  Data4: Yup.string()
    .matches(/^[0-9\s]*$/, "only_digits")
    .test("maxDigits", "data_digits_valid", (value) => {
      if (value === undefined) {
        return true;
      }
      return value.toString().length <= 4;
    }),
  Data5: Yup.string()
    .matches(/^[0-9\s]*$/, "only_digits")
    .test("maxDigits", "data_digits_valid", (value) => {
      if (value === undefined) {
        return true;
      }
      return value.toString().length <= 4;
    }),
  Data6: Yup.string()
    .matches(/^[0-9\s]*$/, "only_digits")
    .test("maxDigits", "data_digits_valid", (value) => {
      if (value === undefined) {
        return true;
      }
      return value.toString().length <= 4;
    }),
});

export const AddSpecialSchedulerSchema = Yup.object({
  ScheduleType: Yup.number().min(0, "field_required"),
  StartDate: Yup.string().required("field_required"),
  EndDate: Yup.string().required("field_required"),
  StartTime: Yup.string().required("field_required"),
  EndTime: Yup.string().required("field_required"),
  Data1: Yup.string()
    .matches(/^[0-9\s]*$/, "only_digits")
    .test("maxDigits", "data_digits_valid", (value) => {
      if (value === undefined) {
        return true;
      }
      return value.toString().length <= 4;
    }),
  Data2: Yup.string()
    .matches(/^[0-9\s]*$/, "only_digits")
    .test("maxDigits", "data_digits_valid", (value) => {
      if (value === undefined) {
        return true;
      }
      return value.toString().length <= 4;
    }),
  Data3: Yup.string()
    .matches(/^[0-9\s]*$/, "only_digits")
    .test("maxDigits", "data_digits_valid", (value) => {
      if (value === undefined) {
        return true;
      }
      return value.toString().length <= 4;
    }),
  Data4: Yup.string()
    .matches(/^[0-9\s]*$/, "only_digits")
    .test("maxDigits", "data_digits_valid", (value) => {
      if (value === undefined) {
        return true;
      }
      return value.toString().length <= 4;
    }),
  Data5: Yup.string()
    .matches(/^[0-9\s]*$/, "only_digits")
    .test("maxDigits", "data_digits_valid", (value) => {
      if (value === undefined) {
        return true;
      }
      return value.toString().length <= 4;
    }),
  Data6: Yup.string()
    .matches(/^[0-9\s]*$/, "only_digits")
    .test("maxDigits", "data_digits_valid", (value) => {
      if (value === undefined) {
        return true;
      }
      return value.toString().length <= 4;
    }),
});

export const AddParkingLotStatusImageSchema = Yup.object({
  Status: Yup.number().required("field_required").min(0, "field_required"),
  FolderId: Yup.number().required("field_required").min(0, "field_required"),
  Image: Yup.number().required("field_required").min(0, "field_required"),
});

export const AddVideoMediaValidateSchema = Yup.object({
  VideoName: Yup.string()
    .required("field_required")
    .max(20, "char_limit_reached"),
  // IsDefaultSettings: Yup.boolean()
  // .required("field_required"),
});

export const setThresholdValidationSchema = Yup.object().shape({
  CategoryCounts: Yup.array().of(
    Yup.object()
      .shape({
        fullAfter: Yup.number()
          .required("field_required")
          .min(0, "Full must be at least 0"),
        availUpto: Yup.number()
          .required("field_required")
          .min(0, "Available must be at least 0"),
        CategoryId: Yup.number().required(),
        Count: Yup.number().required().min(0, "Count must be at least 0"),
      })
      .test(
        "sum-check",
        "Sum of full and available should not exceed the count",
        function (value) {
          const { fullAfter, availUpto, Count, CategoryId } = value;
          let availCount = availUpto - 1;
          let crowdedCount = availUpto - fullAfter + 1;
          let fullCount = Count - fullAfter;

          if (fullAfter > Count) {
            return this.createError({
              path: `${this.path}.fullAfter`,
              message: `full_cannot_greater_count`,
            });
          }

          if (availUpto > Count) {
            return this.createError({
              path: `${this.path}.availUpto`,
              message: `available_cannot_greater_count`,
            });
          }

          if (availUpto > fullAfter) {
            return this.createError({
              path: `${this.path}.availUpto`, // set the error on the specific path
              message: `available_cannot_greater_full`,
            });
          }

          if (availCount + Math.abs(crowdedCount) + fullCount > Count) {
            return this.createError({
              path: `${this.path}.availUpto`,
              message: `threshold_incorrect`,
            });
          }
          return true;
        }
      )
  ),
});

export const LinkbaseInValidationSchema = Yup.object().shape({
  SimNumber: Yup.string()
    .required("field_required")
    .max(30, "sim_number_range")
    .test("must_greater-than-0", "sim_number_min", function (SimNumber) {
      if (SimNumber)
        return SimNumber ? (BigInt(SimNumber) > 0 ? true : false) : false;
      else return true;
    }),
});

export const CameraValidationSchema = (id: number, isImageChange: boolean) =>
  Yup.object().shape({
    CameraName: Yup.string()
      .max(20, "char_limit_reached")
      .required("field_required"),
    MacAddress: Yup.string()
      .matches(macAddressRegex, "invalid_mac_address")
      .required("field_required"),
    IpAddress: Yup.string().matches(ipRegex, "invalid_ip_address"),
    FolderId: Yup.number().when([], {
      is: () => id < 0 || isImageChange,
      then: (schema) =>
        schema.min(0, "field_required").required("field_required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    Height: Yup.number()
      .min(0, "height_max_min")
      .max(20000, "height_max_min")
      .required("field_required"),
    Width: Yup.number()
      .min(0, "width_max_min")
      .max(20000, "width_max_min")
      .required("field_required"),
    FullImage: Yup.number().when([], {
      is: () => id < 0 || isImageChange,
      then: (schema) =>
        schema.min(0, "field_required").required("field_required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    AvailImage: Yup.number().when([], {
      is: () => id < 0 || isImageChange,
      then: (schema) =>
        schema.min(0, "field_required").required("field_required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    ClosedImage: Yup.number().when([], {
      is: () => id < 0 || isImageChange,
      then: (schema) =>
        schema.min(0, "field_required").required("field_required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    isSlotEnable1: Yup.number().min(0, "field_required"),
    isSlotEnable2: Yup.number().min(0, "field_required"),
    isSlotEnable3: Yup.number().min(0, "field_required"),
    isSlotEnable4: Yup.number().min(0, "field_required"),
    isSlotEnable5: Yup.number().min(0, "field_required"),
    isSlotEnable6: Yup.number().min(0, "field_required"),
    slotType_1: Yup.number().when("isSlotEnable1", {
      is: 1,
      then: (schema) => schema.notRequired(),
      otherwise: (schema) =>
        schema.min(0, "field_required").required("field_required"),
    }),
    slotType_2: Yup.number().when("isSlotEnable2", {
      is: 1,
      then: (schema) => schema.notRequired(),
      otherwise: (schema) =>
        schema.min(0, "field_required").required("field_required"),
    }),
    slotType_3: Yup.number().when("isSlotEnable3", {
      is: 1,
      then: (schema) => schema.notRequired(),
      otherwise: (schema) =>
        schema.min(0, "field_required").required("field_required"),
    }),
    slotType_4: Yup.number().when("isSlotEnable4", {
      is: 1,
      then: (schema) => schema.notRequired(),
      otherwise: (schema) =>
        schema.min(0, "field_required").required("field_required"),
    }),
    slotType_5: Yup.number().when("isSlotEnable5", {
      is: 1,
      then: (schema) => schema.notRequired(),
      otherwise: (schema) =>
        schema.min(0, "field_required").required("field_required"),
    }),
    slotType_6: Yup.number().when("isSlotEnable6", {
      is: 1,
      then: (schema) => schema.notRequired(),
      otherwise: (schema) =>
        schema.min(0, "field_required").required("field_required"),
    }),
  });

export const UploadImageMediaValidateSchema = Yup.object({
  ImageFile: Yup.mixed()
    .nullable()
    .required("field_required")
    .test("csv", "only_image_files", (value: any) => {
      let val;
      if (value) {
        val = value?.split(".");
        return (
          val[1].toLowerCase() === "jpg" ||
          val[1].toLowerCase() === "jpeg" ||
          val[1].toLowerCase() === "png" ||
          val[1].toLowerCase() === "svg"
        );
      } else return false;
    }),
});

export const UploadVideoMediaValidateSchema = Yup.object({
  VideoFile: Yup.mixed()
    .nullable()
    .required("field_required")
    .test("csv", "only_video_files", (value: any) => {
      let val;
      if (value) {
        val = value?.split(".");
        return val[1].toLowerCase() === "mp4";
      } else return false;
    }),
});

export const EditSystemUserValidateSchema = Yup.object({
  UserName: Yup.string()
    .required("field_required")
    .min(1, "min_user_name_length_required")
    .max(25, "min_user_name_length_required"),
  Email: Yup.string()
    .matches(
      /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([一-龠ぁ-ゔァ-ヴー々〆〤a-zA-Z\-0-9]+\.)+[一-龠ぁ-ゔァ-ヴー々〆〤a-zA-Z]{2,}))$/,
      "invalid_login_id"
    )
    .min(1, "login_id_length_required")
    .max(50, "login_id_length_required")
    .required("field_required")
    .email(),
  UserType: Yup.number().min(0, "field_required"),
});

export const tenantDetailValidateSchema = Yup.object({
  OrganizationName: Yup.string()
    .required("field_required")
    .max(256, "kitchen_char_limit_reached")
    .test("no-leading-whitespace", "whitespace_error", (value) => {
      if (typeof value !== "string") return false;
      return !value.startsWith(" ");
    }),

  OrganizationNickName: Yup.string()
    .required("field_required")
    .max(256, "kitchen_char_limit_reached")
    .test("no-leading-whitespace", "whitespace_error", (value) => {
      if (typeof value !== "string") return false;
      return !value.startsWith(" ");
    }),

  Phone: Yup.string()
    .nullable()
    .test("whitespace_error", "char_limit_reached_phone", function (value) {
      return !value || (value.length >= 6 && value.length <= 10);
    }),

  Address: Yup.string().max(256, "kitchen_char_limit_reached"),

  Description: Yup.string().max(256, "kitchen_char_limit_reached"),
});

export const AddBranchValidateSchema = Yup.object({
  BranchName: Yup.string()
    .max(20, "char_limit_reached")
    .required("field_required")
    .test("no-leading-whitespace", "whitespace_error", (value) => {
      if (typeof value !== "string") return false;
      return !value.startsWith(" ");
    }),
  Comment: Yup.string().max(500, "comment_limit_reach"),
});

export const AddParkingLotDetailsValidateSchema = Yup.object({
  BranchId: Yup.number().min(1, "field_required"),
  CurrentStatus: Yup.number().min(0, "field_required"),
  PlName: Yup.string()
    .max(20, "char_limit_reached")
    .required("field_required")
    .test("no-leading-whitespace", "whitespace_error", (value) => {
      if (typeof value !== "string") return false;
      return !value.startsWith(" ");
    }),
  ParkingLotId: Yup.string()
    .max(20, "char_limit_reached")
    .required("field_required")
    .test("no-leading-whitespace", "whitespace_error", (value) => {
      if (typeof value !== "string") return false;
      return !value.startsWith(" ");
    }),
  Comment: Yup.string().max(500, "comment_limit_reach"),
});

export const AddParkingLotCategoryDetailsValidateSchema = Yup.object().shape({
  ParkingLotsCategories: Yup.array().of(
    Yup.object().shape({
      Description: Yup.string()
        .max(20, "char_limit_reached")
        .required("field_required")
        .test("no-leading-whitespace", "whitespace_error", (value) => {
          if (typeof value !== "string") return false;
          return !value.startsWith(" ");
        }),
    })
  ),
});

export const AddMediaDefaultFolderValidateSchema = Yup.object({
  FolderName: Yup.string()
    .max(20, "char_limit_reached")
    .required("field_required")
    .test("no-leading-whitespace", "whitespace_error", (value) => {
      if (typeof value !== "string") return false;
      return !value.startsWith(" ");
    }),
  FolderCategoryType: Yup.number()
    .min(0, "field_required")
    .required("field_required"),
  Comment: Yup.string().max(500, "comment_limit_reach"),
});

export const selectCSVValidateSchema = Yup.object({
  CSVFile: Yup.mixed()
    .nullable()
    .required("field_required")
    .test("csv", "only_csv", (value: any) => {
      let val: any;
      if (value) {
        val = value.split(".");
        return val[1].toLowerCase() === "csv";
      } else return false;
    }),
});

export const AddMediaFolderValidateSchema = Yup.object({
  FolderName: Yup.string()
    .max(20, "char_limit_reached")
    .required("field_required")
    .test("no-leading-whitespace", "whitespace_error", (value) => {
      if (typeof value !== "string") return false;
      return !value.startsWith(" ");
    }),
  LeftMargin: Yup.string()
    .required("field_required")
    .matches(/^[0-9-]+$/, "only_digits"),
  TopMargin: Yup.string()
    .required("field_required")
    .matches(/^[0-9-]+$/, "only_digits"),
  FolderCategoryType: Yup.number()
    .min(0, "field_required")
    .required("field_required"),
  Comment: Yup.string().max(500, "comment_limit_reach"),
});

export const AddParkingLotLinkBaseValidateSchema = Yup.object({
  ParkingLotInputTypeEnum: Yup.number().min(0, "field_required"),
  ParkingLotOutputTypeEnum: Yup.number().min(0, "field_required"),
});

export const AddMediaPriceValidateSchema = Yup.object({
  MediaName: Yup.string()
    .required("field_required")
    .max(20, "char_limit_reached"),
  PricingValue: Yup.number()
    .typeError("only_digits")
    .min(0, "pricing_range")
    .max(9, "pricing_range")
    .required("field_required"),
});

export const AddMediaHeightWidthalidateSchema = Yup.object({
  MediaName: Yup.string()
    .required("field_required")
    .max(20, "char_limit_reached"),
  Height: Yup.number()
    .min(0, "height_max_min")
    .max(20000, "height_max_min")
    .required("field_required"),
  Width: Yup.number()
    .min(0, "width_max_min")
    .max(20000, "width_max_min")
    .required("field_required"),
});

export const AddMediaValidateSchema = Yup.object({
  MediaName: Yup.string()
    .required("field_required")
    .max(20, "char_limit_reached"),
});

export const AddMediaFileValidateSchema = Yup.object({
  File: Yup.mixed()
    .nullable()
    .required("field_required")
    .test("csv", "only_media_files", (value: any) => {
      let val;
      if (value) {
        val = value?.split(".");
        return (
          val[1].toLowerCase() === "jpg" ||
          val[1].toLowerCase() === "jpeg" ||
          val[1].toLowerCase() === "png" ||
          val[1].toLowerCase() === "svg" ||
          val[1].toLowerCase() === "mp4"
        );
      } else return false;
    }),
});

export const AddProductValidateSchema = Yup.object({
  Name: Yup.string()
    .min(1, "min_product_name_length_required")
    .max(256, "min_product_name_length_required")
    .required("field_required")
    .test("no-leading-whitespace", "whitespace_error", (value) => {
      if (typeof value !== "string") return false;
      return !value.startsWith(" ");
    }),

  Sku: Yup.string()
    .min(1, "min_product_sku_length_required")
    .max(256, "min_product_sku_length_required")
    .required("field_required")
    .test("no-leading-whitespace", "whitespace_error", (value) => {
      if (typeof value !== "string") return false;
      return !value.startsWith(" ");
    }),

  Variety: Yup.string().max(256, "min_variety_length_required"),

  WeightUnit: Yup.string()
    .required("field_required")
    .min(1, "min_product_wt_length_required")
    .max(25, "min_product_wt_length_required")
    .matches(/^[A-Za-z]+$/, "only_alphabets_allowed"),
});

export const UpdatePasswordValidateSchema = Yup.object({
  OldPassword: Yup.string()
    // .min(8, "min_length_required")
    // .max(20, "min_length_required")
    // .matches(
    //   /^(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/,
    //   "user_validation_password_matches"
    // )
    .required("field_required"),
  NewPassword: Yup.string()
    .min(8, "min_length_required")
    .max(20, "min_length_required")
    .matches(
      /^(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/,
      "user_validation_password_matches"
    )
    .required("field_required")
    .test("different-password", "different_password_message", function (value) {
      return value !== this.parent.OldPassword;
    }),
});

export const WeightedProductValidateSchema = Yup.object({
  Name: Yup.string()
    .min(1, "min_product_name_length_required")
    .max(256, "min_product_name_length_required")
    .required("field_required")
    .test("no-leading-whitespace", "whitespace_error", (value) => {
      if (typeof value !== "string") return false;
      return !value.startsWith(" ");
    }),

  Sku: Yup.string()
    .min(1, "min_product_sku_length_required")
    .max(256, "min_product_sku_length_required")
    .required("not_valid_product")
    .test("no-leading-whitespace", "whitespace_error", (value) => {
      if (typeof value !== "string") return false;
      return !value.startsWith(" ");
    }),
});

export const filterValidationSchema = Yup.object().shape({
  StartDate: Yup.date()
    .nullable()
    .test("start-end-both-or-none", "date_error", function (value) {
      const { EndDate } = this.parent;
      return (!value && !EndDate) || (value && EndDate);
    })
    .test("start-before-end", "start_date_error", function (value) {
      const { EndDate } = this.parent;
      if (!value || !EndDate) return true;
      return value <= EndDate;
    }),

  EndDate: Yup.date()
    .nullable()
    .test("end-start-both-or-none", "date_error", function (value) {
      const { StartDate } = this.parent;
      return (!value && !StartDate) || (value && StartDate);
    })
    .test("end-after-start", "end_date_error", function (value) {
      const { StartDate } = this.parent;
      if (!value || !StartDate) return true;
      return value >= StartDate;
    }),
});
