import { observer } from "mobx-react";
import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { useStore } from "../../../contexts/store-provider";
import {
  getTenantIdFromLocalStorage,
  getUserId,
} from "../../../helpers/localstorage-helper";
import { formatMessage } from "../../../translations/format-message";
import AddWeightedProductForm from "../forms/add-edit-weighted-product-form";
import "./add-edit-product.css";
import { IUpdateWeightedProductSKU } from "../../../models/forms/IAddProduct";

interface IProps {
  id: number;
  modalClosed: () => void;
}

const AddEditPendingWeightedProduct = (props: IProps) => {
  const { productStore, preferencesStore } = useStore();
  const {
    resetProductDetail,
    addUpdateProductState,
    weightedProductDetail,
    selectedTenantId,
    productSkuKeyValueOptions,
    allProductSkuList,
    getWeightedProductState,
    GetAllProductByQuery,
    UpdatePendingWeightedProductSku,
    updatePendingWeightedProductSkuState,
    resetAddUpdatePendingroductState,
    GetPendingWeightedProductByIdService
  } = productStore;
  const initialValues: any = weightedProductDetail;

  /**
   * The useEffect Hook lets you perform side effects in function component.
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered
   * while a dependency of the useEffect has changed.
   */
  useEffect(() => {
    if (props.id > 0) {
      GetPendingWeightedProductByIdService(
        getTenantIdFromLocalStorage() === "-1"
          ? selectedTenantId
          : Number(getTenantIdFromLocalStorage()),
        props.id
      );
    }
  }, []);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "addUpdateProductSuccess" of the useEffect changed.
   */
  useEffect(() => {
    if (updatePendingWeightedProductSkuState.success) {
      toast.success(formatMessage("updated_success"));
      resetAddUpdatePendingroductState();
      onModalClosed();
    }
  }, [updatePendingWeightedProductSkuState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "productDetailError" of the useEffect changed.
   */
  // useEffect(() => {
  //   if (addUpdateProductState.error) {
  //     toast.error(formatMessage(addUpdateProductState.error));
  //   }
  // }, [addUpdateProductState.error]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "addUpdateProductError" of the useEffect changed.
   */
  useEffect(() => {
    if (updatePendingWeightedProductSkuState.error) {
      toast.error(formatMessage(updatePendingWeightedProductSkuState.error));
      resetAddUpdatePendingroductState();
    }
  }, [updatePendingWeightedProductSkuState.error]);

  /**
   * This function adds the product when "Id < 0" and updates the Existing product when Id > 0 by providing form values to the
   * correponding function
   */
  const onSubmitForm = (values: any) => {
    var addObj: any = {
      UserId: JSON.parse(getUserId()!),
      Sku: values.Sku.trim(),
      Name: values.Name.trim(),
      TenantId: Number(getTenantIdFromLocalStorage()),
      WeightUnit: values.Units,
    };

    var updateObj: IUpdateWeightedProductSKU = {
      TenantId:
        getTenantIdFromLocalStorage() === "-1"
          ? selectedTenantId
          : Number(getTenantIdFromLocalStorage()),
      Id: props.id,
      Sku: values.Sku.trim(),
    };
    UpdatePendingWeightedProductSku(updateObj);
  };

  const onModalClosed = () => {
    resetProductDetail();
    props.modalClosed();
  };

  return (
    <React.Fragment>
      <AddWeightedProductForm
        initialValues={initialValues}
        id={props.id}
        submitHandler={onSubmitForm}
        onModalClosed={onModalClosed}
        getWeightedProductState={getWeightedProductState.inProgress}
        addUpdateUserLoading={addUpdateProductState.inProgress}
        userControlOptions={productSkuKeyValueOptions}
        allData={allProductSkuList}
        tenantId={
          getTenantIdFromLocalStorage() === "-1"
            ? selectedTenantId
            : Number(getTenantIdFromLocalStorage())
        }
        SelectedSku={
          allProductSkuList.length > 0 &&
          allProductSkuList.find(
            (obj: { Id: number }) => obj.Id === initialValues?.Id
          )
        }
        getAllProductByQuery={GetAllProductByQuery}
      />
    </React.Fragment>
  );
};

export default observer(AddEditPendingWeightedProduct);
