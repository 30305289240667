

/*
<summary>
  This functional component shows the selected product details.
  Developer:Raman Sharma, Created Date: 21-August-2024
</summary>
<returns>Returns JSX</returns>
*/

import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";

import { useStore } from "../../contexts/store-provider";
import { getTenantIdFromLocalStorage } from "../../helpers/localstorage-helper";
interface IProps {
  selectedRowId: any;
}

const ProductDetails = (props: IProps) => {
  const { productStore } = useStore();
  const { GetProductByIdLocalService, selectedTenantId, productDetail } = productStore;
  const productDetails: any = productDetail;

  useEffect(() => {
    if (props.selectedRowId > 0) {
      GetProductByIdLocalService(props.selectedRowId, getTenantIdFromLocalStorage() === "-1" ? selectedTenantId : Number(getTenantIdFromLocalStorage()));
    }
  }, [props.selectedRowId]);

  return (
    <React.Fragment>

      <div className="px-4  py-3">
        <div className="row">
          <div className="col-lg-6">
            <h3 className="m-0"><FormattedMessage id={"display_name"} /></h3>
          </div>
          <div className="col-lg-6">
            <p className="mw-100 mb-0 text-muted text-end">{productDetails.Name}</p>
          </div>
        </div>

        <hr />
        <div className="row">
          <div className="col-lg-6">
            <h3 className="m-0">      <FormattedMessage id={"Sku"} />{" "}</h3>
          </div>
          <div className="col-lg-6">
            <p className="mw-100 mb-0 text-muted text-end"> {productDetails.Sku}</p>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-lg-6">
            <h3 className="m-0">      <FormattedMessage id={"WeightUnit"} />{" "}</h3>
          </div>
          <div className="col-lg-6">
            <p className="mw-100 mb-0 text-muted text-end"> {productDetails.WeightUnit}</p>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-lg-6">
            <h3 className="m-0">     <FormattedMessage id={"variety"} /></h3></div>
          <div className="col-lg-6">   <p className="mw-100 mb-0 text-muted text-end">   {productDetails.Variety}</p>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className={`${productDetails.Description === 'N/A' ? "col-lg-6" : "col-lg-12"}`}>
            <h3 className="m-0">
              <FormattedMessage id={"description"} />
            </h3>
          </div>
          <div className={`${productDetails.Description === 'N/A' ? "col-lg-6" : "col-lg-12"}`}> <p className={`"mt-1 w-100 text-muted " ${productDetails.Description === 'N/A' ? "text-end" : " "}`}>  {productDetails.Description}</p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default observer(ProductDetails);

